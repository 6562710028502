/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="row">
    <div class="col-12">
      <!-- <div
        class="salert alert alert-custom alert-white alert-shadow fade show gutter-b alert-light"
      >
        <div class="alert-text">
          <div>
            <p>search</p>
          </div>
        </div>
      </div>-->

      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Qo'shimcha shartnoma registratsiyasi</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-text-field
                label="Firma nomi"
                disabled
                v-model="getClientContract.client_name"
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="col-4">
              <div v-if="getClientContract.client_inn == null">
                <v-text-field
                  label="INN topilmadi"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div v-else>
                <v-text-field
                  disabled
                  label="INN"
                  v-model="getClientContract.client_inn"
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>

            <div class="col-4 pr-0">
              <v-select
                @change="payChanged"
                v-model="getClientContract.payment_type1"
                item-text="state"
                outlined
                dense
                item-value="abbr"
                :items="paymentTypeItems"
                label="Turi"
              ></v-select>
            </div>
          </div>

          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-select
                @change="changed"
                v-model="getClientContract.contract_type1"
                item-text="state"
                outlined
                dense
                item-value="abbr"
                :items="items"
                label="Shartnoma turi"
              ></v-select>
            </div>
            <div class="col-4">
              <v-select
                v-model="getClientContract.is_active_status1"
                item-text="state"
                outlined
                dense
                item-value="abbr"
                :items="statusItems"
                label="Statusi"
              ></v-select>
            </div>
            <div class="col-4 pr-0">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getClientContract.start_date"
                    label="Shartnoma sanasi"
                    append-icon="event"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="getClientContract.start_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-text-field
                label="Shartnoma nomeri"
                v-model="getClientContract.contract_number"
                outlined
                dense
              ></v-text-field>
            </div>
            <div
              class="col-4"
              v-if="getClientContract.contract_type == 'Muddatli'"
            >
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getClientContract.end_date"
                    label="Shartnoma tugash sanasi"
                    append-icon="event"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="getClientContract.end_date"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div
              class="col-4 pr-0"
              v-if="getClientContract.contract_type == 'Summa'"
            >
              <v-text-field
                label="Shartnoma summasi"
                v-model="getClientContract.amount"
                outlined
                v-money="money"
                dense
              ></v-text-field>
            </div>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <v-btn
                color="success"
                class="
                  btn btn-light-primary
                  font-weight-bold
                  text-uppercase
                  px-9
                  py-4
                "
                @click="save"
              >
                Saqlash
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  data() {
    return {
      menu3: false,
      menu2: false,
      paymentType: '',
      statusItems: [
        { state: 'Faol', abbr: true },
        { state: 'Faolmas', abbr: false }
      ],
      paymentTypeItems: [
        { state: 'Kelishuv', abbr: 'NAQD' },
        { state: 'Shartnoma', abbr: 'CONT' }
      ],
      items: [
        { state: 'Muddatli', abbr: 'MU' },
        { state: 'Muddati cheklanmagan', abbr: 'MS' },
        { state: "Summaga bog'liq", abbr: 'SU' }
      ],
      money: {
        decimal: ',',
        thousands: ' ',
        precision: 0,
        masked: false
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('getClientContract', this.$route.params.id)
  },
  computed: {
    getClientContract() {
      const data = this.$store.state.requests.allContracts
      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Shartnoma registratsiyasi' }
    ])
  },
  methods: {
    save() {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.getClientContract.amount)) {
        this.getClientContract.amount = this.getClientContract.amount.replace(
          /\s/g,
          ''
        )
      }
      console.log('data --- -- -', this.getClientContract.is_active_status1)
      const data = {
        client: this.getClientContract.client,
        contract_type: this.getClientContract.contract_type1,
        end_date: this.getClientContract.end_date,
        start_date: this.getClientContract.start_date,
        contract_number: this.getClientContract.contract_number,
        amount: this.getClientContract.amount,
        is_active: this.getClientContract.is_active_status1,
        payment_type: this.getClientContract.payment_type1
      }
      for (var key in data) {
        if (data[key] == null || data[key] == '') delete data[key]
      }
      this.$store.dispatch('updateContract', {
        data: data,
        id: this.$route.params.id
      })
    }
  }
}
</script>
